@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Nunito Sans', sans-serif;
}

/* ===== Scrollbar Customization CSS ===== */
/* Firefox */
* {
    scrollbar-width: auto;
    scrollbar-color: #4691F2 #ebebeb;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 8px;
}

*::-webkit-scrollbar-track {
    background: #ebebeb;
    border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
    background-color: #febc95;
    border-radius: 10px;
    border: none;
}

*::-webkit-scrollbar-thumb:hover {
    background-color: #fe8740;
}

@import url('https://fonts.googleapis.com/css2?family=Aguafina+Script&family=Geologica&family=DM+Sans:wght@400;700&family=DM+Serif+Display&family=Montserrat:wght@400;700&family=Urbanist:wght@400;700&display=swap');





